import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
});

instance.defaults.headers.post['Accept'] = 'application/json';
instance.defaults.headers.get['Accept'] = 'application/json';
instance.defaults.headers.put['Accept'] = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.get['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';

instance.interceptors.request.use(
    config => {

        if (!config.headers.Authorization) {
            const token = getData()

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }

        return config;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use(
    res => res,
    err => {
        if (err.response != undefined) {
            if (err.response.status === 401) {
                //store.dispatch(user({token: '', mobile: '', name: ''}));
                Cookies.remove('_user_auth');
                window.location = '/signin';
                throw new Error('login expired');
            }
        }

        throw err;
    }
);

const getData = () => {
    try {
        const value = Cookies.get('_user_auth')
        if (value !== undefined) {
            return value;
        }
    } catch (e) {
        // error reading value
    }
}

function returnAxiosInstance() {
    return instance
}

export function get(url) {
    const axios = returnAxiosInstance();
    return axios.get(url);
}

export function post(url, requestData) {
    const axios = returnAxiosInstance();
    return axios.post(url, requestData);
}

export function put(url, requestData) {
    const axios = returnAxiosInstance();
    return axios.put(url, requestData);
}

export function deleteM(url, requestData) {
    const axios = returnAxiosInstance();
    return axios.delete(url, requestData);
}