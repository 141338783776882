import { get, post } from '../utility/Adapter';
import { API_PROFILE, API_PROFILE_BASIC, API_PROFILE_ADDRESS, API_PROFILE_OTHERS, API_PROFILE_SOCIAL, API_HOSPITAL, API_JOB_ATTRIBUTES } from './Constants';

export const Profile = () => {
    return get(API_PROFILE);
}

export const SaveBasic = (data) => {
    return post(API_PROFILE_BASIC, data);
}

export const SaveAddress = (data) => {
    return post(API_PROFILE_ADDRESS, data);
}

export const SaveOthers = (data) => {
    return post(API_PROFILE_OTHERS, data);
}

export const SaveSocial = (data) => {
    return post(API_PROFILE_SOCIAL, data);
}

export const SaveHospital = (data) => {
    return post(API_HOSPITAL, data);
}

export const Hospital = () => {
    return get(API_HOSPITAL);
}

export const JobAttributes = () => {
    return get(API_JOB_ATTRIBUTES);
}

//export default Profile;