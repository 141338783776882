import { useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import Subscribe from "../../../components/Subscribe";
import { Profile, SaveHospital, Hospital } from '../../../apis/User';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import Menus from "./menus";


const HospitalProfile = () => {

    const alert = useAlert();
    const [profile, setProfile] = useState(
        { name: "", email: "", phone: "", type: 0, job_title: "", address: "", city: "", state: "", pincode: "", country: "", facebook: "", twitter: "", linkedin: "", instagram: "" }
    );

    const [loadingBasic, setLoadingBasic] = useState(false);

    const [hospital, setHospital] = useState({
        "name": "",
        "description": "",
        "phone": "",
        "email": "",
        "website": "",
        "short_description": "",
        "year": "",
        "beds": "",
        "employees": "",

    });

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    useEffect(() => {
        getProfile();
        getHospital();
    }, []);

    const getProfile = async () => {
        Profile().then(async function (response) {
            setProfile(response.data)
        });
    }

    const getHospital = async () => {
        Hospital().then(async function (response) {
            setHospital(response.data)
        });
    }

    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }

    const validateBasic = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Please enter name';
        } else if (values.name.length < 2) {
            errors.name = 'Minimum name length is 2 charector';
        }

        if (!values.short_description) {
            errors.short_description = 'Please enter short description';
        }

        if (!values.email) {
            errors.email = 'Please enter email address';
        }

        if (!values.phone) {
            errors.phone = 'Please enter phone number';
        } else if (values.phone.length > 10) {
            errors.phone = 'Must be 10 characters or less';
        }

        if (!values.address) {
            errors.address = 'Please enter address';
        } else if (values.address.length < 7) {
            errors.address = 'Minimum address length is 6 charector';
        }

        if (!values.city) {
            errors.city = 'Please enter city';
        } else if (values.city.length < 2) {
            errors.city = 'Must be 2 characters or less';
        }

        if (!values.state) {
            errors.state = 'Please enter state';
        } else if (values.state.length < 2) {
            errors.state = 'Must be 2 characters or less';
        }

        if (!values.pincode) {
            errors.pincode = 'Please enter pincode';
        } else if (values.pincode.length < 6) {
            errors.pincode = 'Must be 6 characters or less';
        }

        if (!values.country) {
            errors.country = 'Please enter country';
        } else if (values.country.length < 2) {
            errors.country = 'Must be 2 characters or less';
        }

        if (!values.latitude) {
            errors.latitude = 'Please enter latitude';
        } else if (values.latitude.length < 6) {
            errors.latitude = 'Please enter latitude';
        }

        if (!values.longitude) {
            errors.longitude = 'Please enter longitude';
        } else if (values.longitude.length < 6) {
            errors.longitude = 'Please enter longitude';
        }

        if (values.facebook != undefined)
            if (values.facebook.length > 0) {
                if (!isValidUrl(values.facebook))
                    errors.facebook = 'Please enter valid url';
            }

        if (values.twitter != undefined)
            if (values.twitter.length > 0) {
                if (!isValidUrl(values.twitter))
                    errors.twitter = 'Please enter valid url';
            }

        if (values.linkedin != undefined)
            if (values.linkedin.length > 0) {
                if (!isValidUrl(values.linkedin))
                    errors.linkedin = 'Please enter valid url';
            }

        if (values.instagram != undefined)
            if (values.instagram.length > 0) {
                if (!isValidUrl(values.instagram))
                    errors.instagram = 'Please enter valid url';
            }


        return errors;
    };

    const formikBasic = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: hospital.name,
            short_description: hospital.short_description,
            description: hospital.description,
            phone: hospital.phone,
            email: hospital.email,
            website: hospital.website,
            address: hospital.address,
            city: hospital.city,
            state: hospital.state,
            pincode: hospital.pincode,
            country: hospital.country,
            facebook: hospital.facebook,
            twitter: hospital.twitter,
            linkedin: hospital.linkedin,
            instagram: hospital.instagram,
            year: hospital.year,
            beds: hospital.beds,
            employees: hospital.employees,
            longitude: hospital.longitude,
            latitude: hospital.latitude,
        },
        validate: validateBasic,
        onSubmit: async function (values) {

            if (editorRef.current) {
                values.description = editorRef.current.getContent();
            }

            setLoadingBasic(true);
            await SaveHospital(values);
            setLoadingBasic(false);
            alert.success('Successfully updated!');
        },
    });


    return <>
        <section class="account-section ptb-100" style={{ marginLeft: '8%', marginRight: '8%' }}>
            <form class="basic-info" onSubmit={formikBasic.handleSubmit}>
                <div class="container-fluid" >
                    <div class="row">
                        <div class="col-md-3">
                            <div class="account-information">
                                <div class="profile-thumb">
                                    <img src="assets/img/account.jpg" alt="account holder image" />
                                    <h3 className="text-capitalize">{profile.name ?? ""}</h3>
                                    <p>{profile.job_title ?? ""}</p>
                                </div>

                                <Menus type={profile.type} page={"hosp_details"} />
                            </div>
                        </div>

                        <div class="col-md-7">
                            <div class="account-details">

                                <h3>Hospital / Clinic Details</h3>


                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label className="form-label">Hospital / Clinic Name</label>
                                            <input type="text" class="form-control" placeholder="Name" name="name" value={formikBasic.values.name ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.name ? <div className="error">{formikBasic.errors.name}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-3">
                                        <div class="form-group">
                                            <label>Hospital / Clinic Short Description</label>
                                            <textarea class="form-control" style={{ height: 140 }} placeholder="Short Description" name="short_description" value={formikBasic.values.short_description ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.short_description ? <div className="error">{formikBasic.errors.short_description}</div> : null}
                                        </div>
                                    </div>



                                    <div class="col-md-12 mt-3">
                                        <label className="mb-2">Hospital / Clinic Description</label>
                                        <Editor
                                            apiKey='nhqz3722wlwyvnc0ukf47tqkcymlj9hu3yzuitpp7jyf15cv'
                                            onInit={(_evt, editor) => editorRef.current = editor}
                                            initialValue={formikBasic.values.description}
                                            init={{
                                                height: 300,
                                                menubar: false,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                ],
                                                toolbar: 'undo redo | blocks | ' +
                                                    'bold italic forecolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="email" class="form-control" placeholder="Email" name="email" value={formikBasic.values.email ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.email ? <div className="error">{formikBasic.errors.email}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label>Phone</label>
                                            <input type="text" class="form-control" placeholder="Phone" name="phone" value={formikBasic.values.phone ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.phone ? <div className="error">{formikBasic.errors.phone}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label>Website</label>
                                            <input type="text" class="form-control" placeholder="Website" name="website" value={formikBasic.values.website ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.website ? <div className="error">{formikBasic.errors.website}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label>Year Founded</label>
                                            <input type="text" class="form-control" placeholder="Year Founded" name="year" value={formikBasic.values.year ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.year ? <div className="error">{formikBasic.errors.year}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label>Number of beds</label>
                                            <input type="text" class="form-control" placeholder="Number of beds" name="beds" value={formikBasic.values.beds ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.beds ? <div className="error">{formikBasic.errors.beds}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label>Number of employees</label>
                                            <input type="text" class="form-control" placeholder="Number of employees" name="employees" value={formikBasic.values.employees ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.employees ? <div className="error">{formikBasic.errors.employees}</div> : null}
                                        </div>
                                    </div>


                                    <h3 className="mt-4">Address</h3><hr />

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Address</label>
                                            <input type="text" class="form-control" placeholder="Address" name="address" value={formikBasic.values.address ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.address ? <div className="error">{formikBasic.errors.address}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-3 mt-3">
                                        <div class="form-group">
                                            <label>City</label>
                                            <input type="text" class="form-control" placeholder="City" name="city" value={formikBasic.values.city ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.city ? <div className="error">{formikBasic.errors.city}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-3 mt-3">
                                        <div class="form-group">
                                            <label>State</label>
                                            <input type="text" class="form-control" placeholder="State" name="state" value={formikBasic.values.state ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.state ? <div className="error">{formikBasic.errors.state}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-3 mt-3">
                                        <div class="form-group">
                                            <label>Pincode</label>
                                            <input type="text" class="form-control" placeholder="Pincode" name="pincode" value={formikBasic.values.pincode ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.pincode ? <div className="error">{formikBasic.errors.pincode}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-3 mt-3">
                                        <div class="form-group">
                                            <label>Country</label>
                                            <input type="text" class="form-control" placeholder="Country" name="country" value={formikBasic.values.country ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.country ? <div className="error">{formikBasic.errors.country}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-6 mt-3">
                                        <div class="form-group">
                                            <label>Latitude <a style={{ fontSize: 14 }} href="https://www.latlong.net/convert-address-to-lat-long.html" target="_blank">Go here to get Latitude from address.</a></label>
                                            <input type="text" class="form-control" placeholder="Location Latitude" name="latitude" value={formikBasic.values.latitude ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.latitude ? <div className="error">{formikBasic.errors.latitude}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-6 mt-3">
                                        <div class="form-group">
                                            <label>Longitude <a style={{ fontSize: 14 }} href="https://www.latlong.net/convert-address-to-lat-long.html" target="_blank">Go here to get Longitude from address.</a></label>
                                            <input type="text" class="form-control" placeholder="Location Longitude" name="longitude" value={formikBasic.values.longitude ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.longitude ? <div className="error">{formikBasic.errors.longitude}</div> : null}
                                        </div>
                                    </div>

                                    <h3 className="mt-4">Social links</h3><hr />

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Facebook</label>
                                            <input type="text" name="facebook" class="form-control" placeholder="www.facebook.com/user" value={formikBasic.values.facebook ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.facebook ? <div className="error">{formikBasic.errors.facebook}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Twitter</label>
                                            <input type="text" name="twitter" class="form-control" placeholder="www.twitter.com/user" value={formikBasic.values.twitter ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.twitter ? <div className="error">{formikBasic.errors.twitter}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Linkedin</label>
                                            <input type="text" name="linkedin" class="form-control" placeholder="www.Linkedin.com/user" value={formikBasic.values.linkedin ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.linkedin ? <div className="error">{formikBasic.errors.linkedin}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Instagram</label>
                                            <input type="text" name="instagram" class="form-control" placeholder="www.instagram.com/user" value={formikBasic.values.instagram ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.instagram ? <div className="error">{formikBasic.errors.instagram}</div> : null}
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-4">
                                        <button type="submit" class="account-btn">
                                            {

                                                loadingBasic == true
                                                    ?
                                                    <><i class="fa fa-spinner fa-spin"></i> Saving...</>
                                                    :
                                                    "Save"
                                            }
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="account-details">
                                <h6>Logo [ 500px x 500px ] </h6>
                                <div style={{ padding: 10, border: '1px dotted grey', borderRadius: 10, textAlign: "center" }}>
                                    <img src="http://127.0.0.1:8000/vendor/core/core/base/images/placeholder.png" style={{ width: '100%' }} />
                                    <a href="javascript:void(0);" style={{ fontSize: 13 }}>Click here to change</a>
                                </div>

                                <h6 className="mt-4">Cover Image [ 1920px x 300px ] </h6>
                                <div style={{ padding: 10, border: '1px dotted grey', borderRadius: 10, textAlign: "center" }}>
                                    <img src="http://127.0.0.1:8000/vendor/core/core/base/images/placeholder.png" style={{ width: '100%' }} />
                                    <a href="javascript:void(0);" style={{ fontSize: 13 }}>Click here to change</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>

        <Subscribe />
    </>
}

export default HospitalProfile;