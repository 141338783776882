import { useState, useEffect } from "react";
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import axios from 'axios';

import Subscribe from "../../components/Subscribe";

const SignIn = () => {
    const [error, setError] = useState("");
    const validate = values => {
        const errors = {};


        if (!values.email) {
            errors.email = 'Please enter valid email address';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (!values.password) {
            errors.password = 'Please enter password';
        }
        else if (values.password.length < 6) {
            errors.password = 'Minimum password length is six charector';
        }


        return errors;
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validate,
        onSubmit: values => {
            axios.post(process.env.REACT_APP_API + 'login', values)
                .then(function (response) {
                    Cookies.set('_user_auth', response.data.authorisation.token);
                    //setCookie('_user_auth', response.data.authorisation.token, { path: '/' });
                    window.location = './profile';
                })
                .catch(function (error) {

                    if (error.response != undefined) {
                        if (error.response.data.error_code == 101) {
                            setError("Email address is not found in our system.Please enter new one.");
                        }
                        else if (error.response.data.error_code == 104) {
                            setError("Phone number is already exists.Please enter new one.");
                        }
                        else {
                            setError("Something went wrong.Please contact our support team");
                        }
                    }
                });

        },
    });


    return (
        <>
            <section class="page-title title-bg12">
                <div class="d-table">
                    <div class="d-table-cell">
                        <h2>Sign In</h2>
                        <ul>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Sign In</li>
                        </ul>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </section>

            <div class="signin-section ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-8 offset-md-2 offset-lg-3">
                            <form class="signin-form" onSubmit={formik.handleSubmit}>

                                {
                                    error != "" &&

                                    <div class="alert alert-danger">
                                        <strong>Error!</strong> {error}.
                                    </div>
                                }


                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" class="form-control" placeholder="Enter Your Email" name="email" onChange={formik.handleChange} value={formik.values.email} />
                                    {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                                </div>

                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" class="form-control" placeholder="Enter Your Password" name="password" onChange={formik.handleChange} value={formik.values.password} />
                                    {formik.errors.password ? <div className="error">{formik.errors.password}</div> : null}
                                </div>

                                <div class="signin-btn text-center">
                                    <button type="submit">Sign In</button>
                                </div>



                                <div class="create-btn text-center">
                                    <p>Not have an account?
                                        <a href="./signup">
                                            Create an account
                                            <i class='bx bx-chevrons-right bx-fade-right'></i>
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Subscribe />

        </>
    )
}

export default SignIn;