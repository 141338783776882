import { useState, useEffect } from "react";
import Cookies from 'js-cookie';

const Header = () => {

    const [loggedin, setLoggedIn] = useState(false);

    useEffect(() => {
        var auth = Cookies.get('_user_auth');

        if (auth != "" && auth != undefined) {
            setLoggedIn(true);
        }

        window.jQuery('.mean-menu').meanmenu({
            meanScreenWidth: "991"
        });

    }, []);

    return (
        <>
            <div class="navbar-area">
                <div class="mobile-nav">
                    <a href="/" class="logo">
                        <img src="assets/img/logo.png" alt="logo" />
                    </a>
                </div>

                <div class="main-nav">
                    <div class="container">
                        <nav class="navbar navbar-expand-lg navbar-light">
                            <a class="navbar-brand" href="/">
                                <img src="assets/img/logo.png" alt="logo" />
                            </a>

                            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul class="navbar-nav m-auto">
                                    <li class="nav-item ">
                                        <a href="/" class="nav-link active">Home</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="about.html" class="nav-link">About</a>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link dropdown-toggle">Jobs</a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a href="find-job.html" class="nav-link">Find A Job</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="post-job.html" class="nav-link">Post A Job</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="job-list.html" class="nav-link">Job List</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="job-grid.html" class="nav-link">Job Grid</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="job-details.html" class="nav-link">Job Details</a>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link dropdown-toggle">Candidates</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a href="candidate.html" class="nav-link">Candidates</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="candidate-details.html" class="nav-link">Candidates Details</a>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link dropdown-toggle">Pages</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a href="company.html" class="nav-link">Company</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="pricing.html" class="nav-link">Pricing</a>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#" class="nav-link dropdown-toggle">Profile</a>

                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <a href="account.html" class="nav-link">Account</a>
                                                    </li>

                                                    <li class="nav-item">
                                                        <a href="#" class="nav-link dropdown-toggle">Member</a>

                                                        <ul class="dropdown-menu">
                                                            <li class="nav-item">
                                                                <a href="sign-in.html" class="nav-link">Sign In</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a href="sign-up.html" class="nav-link">Sign Up</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a href="reset-password.html" class="nav-link">Reset Password</a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li class="nav-item">
                                                        <a href="resume.html" class="nav-link">Resume</a>
                                                    </li>

                                                </ul>
                                            </li>

                                            <li class="nav-item">
                                                <a href="404.html" class="nav-link">404 Page</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="testimonial.html" class="nav-link">Testimonials</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="faq.html" class="nav-link">FAQ</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="catagories.html" class="nav-link">Catagories</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="privacy-policy.html" class="nav-link">Privacy & Policy</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="terms-condition.html" class="nav-link">Terms & Conditions</a>
                                            </li>

                                        </ul>
                                    </li>


                                    <li class="nav-item">
                                        <a href="#" class="nav-link dropdown-toggle">Blog</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a href="blog.html" class="nav-link">Blog</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="blog-two.html" class="nav-link">Blog Two</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="blog-details.html" class="nav-link">Blog Details</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a href="contact.html" class="nav-link">Contact Us</a>
                                    </li>

                                </ul>

                                <div class="other-option">

                                    {
                                        loggedin == false
                                            ?
                                            <>
                                                <a href="./signup" class="signup-btn">Sign Up</a>
                                                <a href="./signin" class="signin-btn">Sign In</a>
                                            </>
                                            :
                                            <a href="./logout" class="signin-btn">Logout</a>
                                    }


                                </div>

                            </div>


                        </nav>
                    </div>
                </div>



            </div>
        </>
    )
}

export default Header;