
import { useState, useEffect } from "react";
import { useFormik } from 'formik';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import Subscribe from "../../components/Subscribe";
import { type } from "@testing-library/user-event/dist/type";



const SignUpPage = () => {

    const [error, setError] = useState("");
    const [cookies, setCookie] = useCookies([]);

    useEffect(() => {
        window.$('document').ready(function () {
            window.$('select').niceSelect();
        });
    }, []);

    const validate = values => {
        const errors = {};
        if (!values.user_name) {
            errors.user_name = '* Please enter name';
        } else if (values.user_name.length > 15) {
            errors.user_name = 'Must be 15 characters or less';
        }

        if (!values.email) {
            errors.email = 'Please enter valid email address';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (!values.phone) {
            errors.phone = 'Please enter phone number';
        } else if (values.phone.length > 10) {
            errors.phone = 'Must be 10 characters or less';
        }

        if (!values.password) {
            errors.password = 'Please enter password';
        }
        else if (values.password.length < 6) {
            errors.password = 'Minimum password length is six charector';
        }


        return errors;
    };

    const formik = useFormik({
        initialValues: {
            user_name: '',
            phone: '',
            email: '',
            password: '',
            type: 1
        },
        validate,
        onSubmit: values => {
            axios.post(process.env.REACT_APP_API + 'signup', values)
                .then(function (response) {
                    setCookie('_user_auth', response.data.authorisation.token, { path: '/' });
                    window.location = './profile';
                })
                .catch(function (error) {

                    if (error.response != undefined) {
                        if (error.response.data.error_code == 101) {
                            setError("Email address is already exists.Please enter new one.");
                        }
                        else if (error.response.data.error_code == 104) {
                            setError("Phone number is already exists.Please enter new one.");
                        }
                        else {
                            setError("Something went wrong.Please contact our support team");
                        }
                    }
                });

        },
    });

    return <>

        <section class="page-title title-bg13">
            <div class="d-table">
                <div class="d-table-cell">
                    <h2>Sign Up</h2>
                    <ul>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>Sign Up</li>
                    </ul>
                </div>
            </div>
            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </section>


        <div class="signup-section ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-8 offset-md-2 offset-lg-3">

                        <form class="signup-form" onSubmit={formik.handleSubmit}>

                            {
                                error != "" &&

                                <div class="alert alert-danger">
                                    <strong>Error!</strong> {error}.
                                </div>
                            }


                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" class="form-control" placeholder="Enter Name" id="user_name" name="user_name" onChange={formik.handleChange} value={formik.values.user_name} />
                                {formik.errors.user_name ? <div className="error">{formik.errors.user_name}</div> : null}
                            </div>

                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" class="form-control" placeholder="Enter Your Email" id="email" name="email" onChange={formik.handleChange} value={formik.values.email} />
                                {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                            </div>

                            <div class="form-group">
                                <label>Phone</label>
                                <input type="number" class="form-control" placeholder="Enter Your Phone Number" name="phone" onChange={formik.handleChange} value={formik.values.phone} />
                                {formik.errors.phone ? <div className="error">{formik.errors.phone}</div> : null}
                            </div>

                            <div class="form-group">
                                <label>Type</label>
                                <select name="type" className="w-100" onChange={formik.handleChange} value={formik.values.type}>
                                    <option value={"1"}>Induvidual</option>
                                    <option value={"2"}>Hospital/Clinic</option>
                                    <option value={"3"}>Freelancers (Doctors) </option>
                                </select>
                            </div>

                            <br />

                            <div class="form-group" style={{ marginTop: 20 }}>
                                <label>Password</label>
                                <input type="password" class="form-control" placeholder="Enter Your Password" name="password" onChange={formik.handleChange} value={formik.values.password} />
                                {formik.errors.password ? <div className="error">{formik.errors.password}</div> : null}
                            </div>

                            <div class="signup-btn text-center">
                                <button type="submit">Sign Up</button>
                            </div>

                            <div class="create-btn text-center">
                                <p>
                                    Have an Account?
                                    <a href="./signin">
                                        Sign In
                                        <i class='bx bx-chevrons-right bx-fade-right'></i>
                                    </a>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <Subscribe />
    </>
}

export default SignUpPage;