import { useState, useEffect, useRef } from "react";
import Subscribe from "../../../components/Subscribe";
import { Profile } from '../../../apis/User';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import Menus from "./menus";
import DataTable from 'react-data-table-component';

const HospitalJobs = () => {

    const alert = useAlert();
    const [profile, setProfile] = useState(
        { name: "", email: "", phone: "", type: 0, job_title: "", address: "", city: "", state: "", pincode: "", country: "", facebook: "", twitter: "", linkedin: "", instagram: "" }
    );

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        Profile().then(async function (response) {
            setProfile(response.data)
        });
    }

    const columns = [
        {
            name: 'Job Title',
            selector: row => row.title,
        },
        {
            name: 'No of Openings',
            selector: row => row.year,
        },
        {
            name: 'No of Applicants',
            selector: row => row.year,
        },
        {
            name: 'Job Date',
            selector: row => row.year,
        },
        {
            name: 'Date',
            selector: row => row.year,
        },
    ];

    const data = [
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]

    return <>
        <section class="account-section ptb-100" style={{ marginLeft: '8%', marginRight: '8%' }}>
            <div class="container-fluid" >
                <div class="row">
                    <div class="col-md-3">
                        <div class="account-information">
                            <div class="profile-thumb">
                                <img src={process.env.REACT_APP_BASE_URL + "assets/img/account.jpg"} alt="account holder image" />
                                <h3 className="text-capitalize">{profile.name ?? ""}</h3>
                                <p>{profile.job_title ?? ""}</p>
                            </div>

                            <Menus type={profile.type} page={"hosp_jobs"} />
                        </div>
                    </div>

                    <div class="col-md-9">
                        <div class="account-details d-flex justify-content-between">
                            <h3>Hospital / Clinic Jobs</h3>
                            <div class="theme-btn text-end">
                                <a href={process.env.REACT_APP_BASE_URL + "profile/hospital-clinics/jobs/add-new"} class="default-btn" style={{ padding: "0px 17px" }}>
                                    Add New Job
                                </a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 mt-4">
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    </>
}

export default HospitalJobs;