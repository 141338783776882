import { useState, useEffect } from "react";


const HomePage = () => {

    useEffect(() => {
        window.$('document').ready(function () {
            window.$('select').niceSelect();
        });
    }, []);


    return (
        <>
            <div class="banner-style-three">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="banner-text">
                                <span>Find Jobs, Employment & Career Opportunities</span>
                                <h1>Find Top IT Jobs For Developers</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                                <div class="theme-btn">
                                    <a href="#" class="default-btn active">Upload your CV</a>
                                    <a href="contact.html" class="default-btn">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Find Section Start */}
            <div class="find-section pb-100">
                <div class="container">
                    <form class="find-form">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Job Title or Keyword" />
                                    <i class="bx bx-search-alt"></i>
                                </div>
                            </div>

                            <div class="col-lg-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail2" placeholder="Location" />
                                    <i class="bx bx-location-plus"></i>
                                </div>
                            </div>

                            <div class="col-lg-3">
                                <select class="category">
                                    <option data-display="Category">Category</option>
                                    <option value="1">Web Development</option>
                                    <option value="2">Graphics Design</option>
                                    <option value="4">Data Entry</option>
                                    <option value="5">Visual Editor</option>
                                    <option value="6">Office Assistant</option>
                                </select>
                            </div>

                            <div class="col-lg-3">
                                <button type="submit" class="find-btn">
                                    Find A Job
                                    <i class='bx bx-search'></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Find Section End */}

            {/*<!-- Job Category Section Start -->*/}
            <div class="category-style-two pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Popular Jobs Category</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <a href="job-list.html">
                                <div class="category-item">
                                    <i class="flaticon-wrench-and-screwdriver-in-cross"></i>
                                    <h3>Construction</h3>
                                    <p>6 new Job</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <a href="job-list.html">
                                <div class="category-item">
                                    <i class="flaticon-accounting"></i>
                                    <h3>Finance</h3>
                                    <p>8 new Job</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <a href="job-list.html">
                                <div class="category-item">
                                    <i class="flaticon-heart"></i>
                                    <h3>Healthcare</h3>
                                    <p>9 new Job</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <a href="job-list.html">
                                <div class="category-item">
                                    <i class="flaticon-computer-1"></i>
                                    <h3>Graphic Design</h3>
                                    <p>6 new Job</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <a href="job-list.html">
                                <div class="category-item">
                                    <i class="flaticon-research"></i>
                                    <h3>Banking Jobs</h3>
                                    <p>5 new Job</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <a href="job-list.html">
                                <div class="category-item">
                                    <i class="flaticon-worker"></i>
                                    <h3>Automotive</h3>
                                    <p>12 new Job</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <a href="job-list.html">
                                <div class="category-item">
                                    <i class="flaticon-graduation-cap"></i>
                                    <h3>Education</h3>
                                    <p>15 new Job</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <a href="job-list.html">
                                <div class="category-item">
                                    <i class="flaticon-computer"></i>
                                    <h3>Data Analysis</h3>
                                    <p>5 new Job</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!-- Job Category Section End -->*/}


            {/*<!-- Grow Business Section Start -->*/}
            <div class="grow-business pb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="grow-text">
                                <div class="section-title">
                                    <h2>Grow Your Business Faster With Premium Advertising</h2>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                                </p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit.
                                </p>

                                <div class="theme-btn">
                                    <a href="#" class="default-btn">Checkout More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="grow-img">
                                <img src="assets/img/grow-img.jpg" alt="grow image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<!-- Grow Business Section End -->*/}


            {/**<!-- Process Video Start --> */}
            <div class="video-section ptb-100">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="video-text text-center">
                                <h2>Our Working Process</h2>
                                <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" class="popup-youtube">
                                    <i class='bx bx-play'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/**<!-- Process Video End --> */}


            {/**<!-- Job Section Start --> */}
            <section class="job-style-two pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Jobs You May Be Interested In</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/1.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">Web Designer, Graphic Designer, UI/UX Designer </a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    Graphics Designer
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    $35000-$38000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    Wellesley Rd, London
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    9 days ago
                                                </li>
                                            </ul>

                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/2.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">Website Developer & Software Developer</a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    Web Developer
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    $3000-$8000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    Garden Road, UK
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    5 days ago
                                                </li>
                                            </ul>

                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/3.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">Application Developer & Web Designer</a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase'></i>
                                                    App Developer
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase'></i>
                                                    $3000-$4000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    State City, USA
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    8 days ago
                                                </li>
                                            </ul>

                                            <span>Part-Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/4.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">Frontend & Backend Developer</a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    Web Developer
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    $5000-$8000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    Drive Post NY 676
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    1 days ago
                                                </li>
                                            </ul>

                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/5.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">IT Department & Manager</a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    Manager
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    $35000-$38000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    Wellesley Rd, London
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    7 days ago
                                                </li>
                                            </ul>

                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/**<!-- Job Section End --> */}


            {/**<!-- Companies Section Start --> */}
            <section class="company-section pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Top Companies</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="company-card">
                                <div class="company-logo">
                                    <a href="job-list.html">
                                        <img src="assets/img/top-company/1.png" alt="company logo" />
                                    </a>
                                </div>
                                <div class="company-text">
                                    <h3>Trophy  & Sans</h3>
                                    <p>
                                        <i class='bx bx-location-plus'></i>
                                        Green Lanes, London
                                    </p>
                                    <a href="job-list.html" class="company-btn">
                                        25 Open Position
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="company-card">
                                <div class="company-logo">
                                    <a href="job-list.html">
                                        <img src="assets/img/top-company/2.png" alt="company logo" />
                                    </a>
                                </div>
                                <div class="company-text">
                                    <h3>Trout Design</h3>
                                    <p>
                                        <i class='bx bx-location-plus'></i>
                                        Park Avenue, Mumbai
                                    </p>
                                    <a href="job-list.html" class="company-btn">
                                        35 Open Position
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="company-card">
                                <div class="company-logo">
                                    <a href="job-list.html">
                                        <img src="assets/img/top-company/3.png" alt="company logo" />
                                    </a>
                                </div>
                                <div class="company-text">
                                    <h3>Resland LTD</h3>
                                    <p>
                                        <i class='bx bx-location-plus'></i>
                                        Betas Quence, London
                                    </p>
                                    <a href="job-list.html" class="company-btn">
                                        20 Open Position
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="company-card">
                                <div class="company-logo">
                                    <a href="job-list.html">
                                        <img src="assets/img/top-company/4.png" alt="company logo" />
                                    </a>
                                </div>
                                <div class="company-text">
                                    <h3>Lawn Hopper</h3>
                                    <p>
                                        <i class='bx bx-location-plus'></i>
                                        Wellesley Rd, London
                                    </p>
                                    <a href="job-list.html" class="company-btn">
                                        45 Open Position
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/**<!-- Companies Section End --> */}

            {/**<!-- Apps Section Start --> */}
            <section class="browse-job ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="browse-jobs-text">
                                <div class="section-title">
                                    <h2>Browse Hundred of Jobs</h2>
                                    <p>We are efficiently delivering tons of jobs straight to your pocket</p>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>

                                <div class="app-btn">
                                    <a href="#" class="app-store active">
                                        <i class='bx bxl-apple' ></i>
                                        <ul>
                                            <li>Download on the</li>
                                            <li>App Store</li>
                                        </ul>
                                    </a>

                                    <a href="#" class="play-store">
                                        <i class='bx bxl-play-store'></i>
                                        <ul>
                                            <li>Get it</li>
                                            <li>Google Play</li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="browse-job-img">
                    <img src="assets/img/mobile.png" alt="mobile image" />
                </div>
            </section>
            {/**<!-- Apps Section End --> */}

            {/**<!-- Counter Section Start --> */}
            <div class="counter-section pt-100 pb-70">
                <div class="container">
                    <div class="row counter-area">
                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-resume"></i>
                                <h2><span>1225</span></h2>
                                <p>Job Posted</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-recruitment"></i>
                                <h2><span>145</span></h2>
                                <p>Job Filed</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-portfolio"></i>
                                <h2><span>170</span></h2>
                                <p>Company</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-employee"></i>
                                <h2><span>125</span></h2>
                                <p>Members</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/**<!-- Counter Section End --> */}


            {/**<!-- Testimonial Section Start --> */}
            <div class="testimonial-style-two ptb-100">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>What Client’s Say About Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>

                    <div class="row">
                        <div class="testimonial-slider-two owl-carousel owl-theme">
                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Alisa Meair</h3>
                                    <p>CEO of  Company</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Adam Smith</h3>
                                    <p>Web Developer</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>John Doe</h3>
                                    <p>Graphics Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/**<!-- Testimonial Section End --> */}

            {/**<!-- Why Choose Section Start --> */}
            <section class="why-choose">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-7 p-0">
                            <div class="why-choose-text pt-100 pb-70">
                                <div class="section-title text-center">
                                    <h2>Why You Choose Jovie?</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorei.</p>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="media">
                                            <i class="flaticon-group align-self-center mr-3"></i>
                                            <div class="media-body">
                                                <h5 class="mt-0">Best Talented People</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="media">
                                            <i class="flaticon-research align-self-center mr-3"></i>
                                            <div class="media-body">
                                                <h5 class="mt-0">Easy To Find Canditate</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="media">
                                            <i class="flaticon-discussion align-self-center mr-3"></i>
                                            <div class="media-body">
                                                <h5 class="mt-0">Easy To Communicate</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="media">
                                            <i class="flaticon-recruitment align-self-center mr-3"></i>
                                            <div class="media-body">
                                                <h5 class="mt-0">Global Recruitment Option</h5>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row counter-area">
                                    <div class="col-md-3 col-6">
                                        <div class="counter-text">
                                            <h2><span>127</span></h2>
                                            <p>Job Posted</p>
                                        </div>
                                    </div>

                                    <div class="col-md-3 col-6">
                                        <div class="counter-text">
                                            <h2><span>137</span></h2>
                                            <p>Job Filed</p>
                                        </div>
                                    </div>

                                    <div class="col-md-3 col-6">
                                        <div class="counter-text">
                                            <h2><span>180</span></h2>
                                            <p>Company</p>
                                        </div>
                                    </div>

                                    <div class="col-md-3 col-6">
                                        <div class="counter-text">
                                            <h2><span>144</span></h2>
                                            <p>Members</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 p-0">
                            <div class="why-choose-img">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/**<!-- Why Choose Section End --> */}

            {/** <!-- Blog Section Start --> */}
            <section class="blog-section pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>News, Tips & Articles</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/1.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            How to Indroduce in Yourself in Job Interview?
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/2.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            Looking for Highly Motivated Product to Build
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/3.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            The Reason Why Software Develope is the Best Job
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/** <!-- Blog Section End --> */}

            {/** <!-- Subscribe Section Start --> */}
            <section class="subscribe-section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <form class="newsletter-form" data-toggle="validator">
                                <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off" />

                                <button class="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/** <!-- Subscribe Section End --> */}
        </>
    )
}


export default HomePage;