import { useState, useEffect, useRef } from "react";
import Subscribe from "../../../components/Subscribe";
import { Profile, JobAttributes } from '../../../apis/User';
import { useFormik } from 'formik';
import { useAlert } from 'react-alert';
import Menus from "./menus";
import { Editor } from '@tinymce/tinymce-react';

const JobsAdd = () => {

    const alert = useAlert();
    const [profile, setProfile] = useState(
        { name: "", email: "", phone: "", type: 0, job_title: "", address: "", city: "", state: "", pincode: "", country: "", facebook: "", twitter: "", linkedin: "", instagram: "" }
    );

    const [loadingBasic, setLoadingBasic] = useState(false);
    const [jobAttributes, setJObAttributes] = useState({
        "categories": [],
        "degree_levels": [],
        "job_skills": [],
        "job_types": [],
        "career_level": [],
        "functional_area": [],
        "job_experience": []
    });

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        Profile().then(async function (response) {
            setProfile(response.data)
        });

        JobAttributes().then(async function (response) {
            setJObAttributes(response.data)
        });
    }

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const validateBasic = values => {
        const errors = {};

        if (!values.job_title) {
            errors.job_title = 'Please enter job title';
        } else if (values.job_title.length < 2) {
            errors.job_title = 'Minimum job title length is 2 charector';
        }


        return errors;
    }

    const formikBasic = useFormik({
        enableReinitialize: true,
        initialValues: {
            job_title: "",
            "short_description": "",
            "no_openings": 1,
            "salary_from": 0,
            "salary_to": 0,

        },
        validate: validateBasic,
        onSubmit: async function (values) {

            if (editorRef.current) {

                if (editorRef.current.getContent().length == 0) {
                    alert.error('Please enter job description!');
                    return;
                }

                values.description = editorRef.current.getContent();
            }

            const categories = document.getElementsByName("category[]");
            const degree_levels = document.getElementById("degree_level[]");
            const job_skills = document.getElementsByName("job_skills[]");
            const job_types = document.getElementsByName("job_types[]");
            const job_experience = document.getElementById("job_experience").value;

            if (categories.length == 0) {
                alert.error('Please select job category!');
                return;
            }

            if (degree_levels.length == 0) {
                alert.error('Please select job degree levels!');
                return;
            }

            if (job_skills.length == 0) {
                alert.error('Please select job skills!');
                return;
            }

            if (job_types.length == 0) {
                alert.error('Please select job types!');
                return;
            }

            if (job_experience.length == 0) {
                alert.error('Please select job experience!');
                return;
            }

            setLoadingBasic(true);
            //await SaveHospital(values);
            setLoadingBasic(false);
            alert.success('Successfully updated!');
        },
    });

    return <>
        <section class="account-section ptb-100" style={{ marginLeft: '8%', marginRight: '8%' }}>
            <div class="container-fluid" >
                <div class="row">
                    <div class="col-md-3">
                        <div class="account-information">
                            <div class="profile-thumb">
                                <img src={process.env.REACT_APP_BASE_URL + "assets/img/account.jpg"} alt="account holder image" />
                                <h3 className="text-capitalize">{profile.name ?? ""}</h3>
                                <p>{profile.job_title ?? ""}</p>
                            </div>

                            <Menus type={profile.type} page={"hosp_jobs"} />
                        </div>
                    </div>

                    <div class="col-md-9">
                        <div class="account-details">
                            <h3 className="mb-4">Create New Job</h3><hr />

                            <form class="basic-info" onSubmit={formikBasic.handleSubmit}>
                                <div class="row">

                                    <div className="col-sm-8">
                                        <div class="form-group">
                                            <label className="form-label">Job Title</label>
                                            <input type="text" class="form-control" placeholder="Job Title" name="job_title" value={formikBasic.values.job_title ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.job_title ? <div className="error">{formikBasic.errors.job_title}</div> : null}
                                        </div>

                                        <div class="form-group mt-4">
                                            <label>Short Description</label>
                                            <textarea class="form-control" style={{ height: 140 }} placeholder="Short Description" name="short_description" value={formikBasic.values.short_description ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.short_description ? <div className="error">{formikBasic.errors.short_description}</div> : null}
                                        </div>

                                        <div class="col-md-12 mt-3">
                                            <label className="mb-2">Description</label>
                                            <Editor
                                                apiKey='nhqz3722wlwyvnc0ukf47tqkcymlj9hu3yzuitpp7jyf15cv'
                                                onInit={(_evt, editor) => editorRef.current = editor}
                                                initialValue={formikBasic.values.description}
                                                init={{
                                                    height: 300,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                            />
                                        </div>

                                        <div class="form-group mt-4">
                                            <label className="form-label">No of Openings</label>
                                            <input type="text" class="form-control" placeholder="No of Openings" name="no_openings" value={formikBasic.values.no_openings ?? ""} onChange={formikBasic.handleChange} />
                                            {formikBasic.errors.no_openings ? <div className="error">{formikBasic.errors.no_openings}</div> : null}
                                        </div>

                                        <div class="row mb-3 mt-4">
                                            <div class="col">
                                                <label class="form-label" for="salary_from">Salary From</label>
                                                <input type="text" class="form-control" id="salary_from" placeholder="1" name="salary_from" value={formikBasic.values.salary_from ?? ""} onChange={formikBasic.handleChange} aria-label="Salary From" />
                                            </div>

                                            <div class="col">
                                                <label class="form-label" for="salary_to">Salary To</label>
                                                <input type="text" class="form-control" id="salary_to" placeholder="1" name="salary_to" value={formikBasic.values.salary_to ?? ""} onChange={formikBasic.handleChange} aria-label="Salary To" />
                                            </div>

                                            <div class="col">
                                                <label class="form-label" for="salary_range">Salary Range</label>
                                                <select id="salary_range" name="salary_range" class="form-control select2 valid" aria-invalid="false">
                                                    <option value="1">Monthly</option>
                                                    <option value="2">Yearly</option>
                                                    <option value="3">Weekly</option>
                                                    <option value="4">Daily</option>
                                                    <option value="5">Hourly</option>
                                                </select>
                                            </div>

                                            <div class="col">
                                                <label class="form-label" for="salary_currency">Salary Currency</label>
                                                <select id="salary_currency" name="salary_currency" class="form-control select2 valid" aria-invalid="false">
                                                    <option value="4">INR</option>
                                                    <option value="1">USD</option>
                                                    <option value="2">EUR</option>
                                                    <option value="3">VND</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row mb-3 mnt-4">
                                            <div class="col">
                                                <label class="form-label" for="start_date">Start Date</label>
                                                <input type="date" class="form-control" id="start_date" name="start_date" value="" aria-label="Start Date" />
                                            </div>
                                            <div class="col">
                                                <label class="form-label" for="end_date">Application closing date</label>
                                                <input type="date" class="form-control" id="end_date" name="end_date" value="" aria-label="End Date" />
                                            </div>
                                        </div>

                                        <div class="row mb-3 mt-4">
                                            <div class="col">
                                                <label class="form-label" for="start_date">Tags</label>
                                                <textarea style={{ height: "150px" }} class="form-control valid" id="tags" name="tags" aria-label="Tags" spellcheck="false" aria-invalid="false">
                                                </textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-4">
                                        <div class="card mb-4">
                                            <div class="card-header">
                                                <h6 class="card-tile mb-0">Job Categories</h6>
                                            </div>
                                            <div class="card-body" style={{ maxHeight: "300px" }}>

                                                {
                                                    jobAttributes.categories.map((category, key) =>
                                                        <div class="form-check">
                                                            <input class="form-check-input" name="category[]" type="checkbox" value={category.id ?? ""} id={"defaultCheck1" + category.id ?? ""} />
                                                            <label class="form-check-label" for={"defaultCheck1" + category.id ?? ""}>
                                                                {category.category ?? ""}
                                                            </label>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>


                                        <div class="card mb-3">
                                            <div class="card-header">
                                                <h6 class="card-tile mb-0">Degree Levels (Educational Qualifications)</h6>
                                            </div>
                                            <div class="card-body" style={{ maxHeight: "300px" }}>

                                                {
                                                    jobAttributes.degree_levels != undefined && jobAttributes.degree_levels.length &&
                                                    jobAttributes.degree_levels.map((dl, k) =>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="degree_level[]" value={dl.id} id={"dl" + dl.id} />
                                                            <label class="form-check-label" for={"dl" + dl.id}>
                                                                {dl.degree_level ?? ""}
                                                            </label>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div class="card mb-3">
                                            <div class="card-header">
                                                <h6 class="card-tile mb-0">Degree Type (Educational Specifications)</h6>
                                            </div>
                                            <div class="card-body degree_type" style={{ maxHeight: "300px" }}><span class="text-muted text-center">Select Degree Level</span></div>
                                        </div>

                                        <div class="card mb-3">
                                            <div class="card-header">
                                                <h6 class="card-tile mb-0">Job Skills</h6>
                                            </div>
                                            <div class="card-body" style={{ maxHeight: "300px" }}>

                                                {
                                                    jobAttributes.job_skills != undefined && jobAttributes.job_skills.length &&
                                                    jobAttributes.job_skills.map((skills, k) =>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="job_skills[]" value={skills.id} id={"sk" + skills.id} />
                                                            <label class="form-check-label" for={"sk" + skills.id}>
                                                                {skills.job_skill ?? ""}
                                                            </label>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div class="card mb-3">
                                            <div class="card-header">
                                                <h6 class="card-tile mb-0">Job Types</h6>
                                            </div>
                                            <div class="card-body" style={{ maxHeight: "300px" }}>
                                                {
                                                    jobAttributes.job_types != undefined && jobAttributes.job_types.length &&
                                                    jobAttributes.job_types.map((types, k) =>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="job_types[]" value={types.id} id={"ty" + types.id} />
                                                            <label class="form-check-label" for={"ty" + types.id}>
                                                                {types.job_type}
                                                            </label>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div class="card mb-3">
                                            <div class="card-header">
                                                <h5 class="card-tile mb-0">Career Level</h5>
                                            </div>
                                            <div class="card-body">
                                                <select name="career_level" id="career_level" class="form-control select2 valid" aria-invalid="false">
                                                    <option value="0">Select</option>
                                                    {
                                                        jobAttributes.career_level != undefined && jobAttributes.career_level.length &&
                                                        jobAttributes.career_level.map((cl, k) =>
                                                            <option value={cl.id}>{cl.career_level}</option>
                                                        )
                                                    }

                                                </select>
                                            </div>
                                        </div>

                                        <div class="card mb-3">
                                            <div class="card-header">
                                                <h5 class="card-tile mb-0">Functional Area</h5>
                                            </div>
                                            <div class="card-body">
                                                <select name="functional_area" id="functional_area" class="form-control select2 valid" aria-invalid="false">
                                                    <option value="0">Select</option>
                                                    {
                                                        jobAttributes.functional_area != undefined && jobAttributes.functional_area.length &&
                                                        jobAttributes.functional_area.map((fa, k) =>
                                                            <option value={fa.id}>{fa.area}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div class="card mb-3">
                                            <div class="card-header">
                                                <h5 class="card-tile mb-0">Job Experience</h5>
                                            </div>
                                            <div class="card-body">
                                                <select name="job_experience" id="job_experience" class="form-control select2 valid" aria-invalid="false">
                                                    <option value="0">Select</option>
                                                    {
                                                        jobAttributes.job_experience != undefined && jobAttributes.job_experience.length &&
                                                        jobAttributes.job_experience.map((je, k) =>
                                                            <option value={je.id}>{je.job_experience ?? ""}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12"><button type="submit" class="account-btn">Save</button></div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    </>
}

export default JobsAdd;