import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import HomePage from "./pages/home/HomePage";
import SignUpPage from "./pages/signup/SignUpPage";
import SignIn from "./pages/signin/SignInPage";
import Logout from "./pages/Logout";

import ProfilePage from "./pages/account/profile/ProfilePage";
import HospitalProfile from "./pages/account/hospitals";
import HospitalJobs from "./pages/account/hospitals/jobs";
import JobsAdd from "./pages/account/hospitals/jobs_add";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="logout" element={<Logout />} />
          <Route path="profile/hospital-clinics" element={<HospitalProfile />} />
          <Route path="profile/hospital-clinics/jobs" element={<HospitalJobs />} />
          <Route path="profile/hospital-clinics/jobs/add-new" element={<JobsAdd />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
