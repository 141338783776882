

const Menus = (props) => {
    return <>
        <ul>
            <li>
                <a href={process.env.REACT_APP_BASE_URL + "profile"} class={props.page == "profile" ? "active" : ""}>
                    <i class='bx bx-user'></i>
                    My Profile
                </a>
            </li>
            {
                props.type == 0 ?
                    <>
                        <li>
                            <a href="resume.html">
                                <i class='bx bxs-file-doc'></i>
                                My Resume
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class='bx bx-briefcase'></i>
                                Applied Job's
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class='bx bx-heart'></i>
                                Saved Jobs
                            </a>
                        </li>
                    </>

                    :
                    <>
                        <li>
                            <a href={process.env.REACT_APP_BASE_URL + "profile/hospital-clinics"} class={props.page == "hosp_details" ? "active" : ""}>
                                <i class='bx bxs-buildings'></i>
                                Hospital/Clinic Details
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class='bx bx-briefcase'></i>
                                Applicants
                            </a>
                        </li>
                        <li>
                            <a href={process.env.REACT_APP_BASE_URL + "profile/hospital-clinics/jobs"} class={props.page == "hosp_jobs" ? "active" : ""}>
                                <i class='bx bx-purchase-tag-alt'></i>
                                Jobs
                            </a>
                        </li>
                    </>
            }


            <li>
                <a href="#">
                    <i class='bx bx-envelope'></i>
                    Messages
                </a>
            </li>

            <li>
                <a href="#">
                    <i class='bx bx-lock-alt' ></i>
                    Change Password
                </a>
            </li>
            <li>
                <a href="#">
                    <i class='bx bx-coffee-togo'></i>
                    Delete Account
                </a>
            </li>
            <li>
                <a href="#">
                    <i class='bx bx-log-out'></i>
                    Log Out
                </a>
            </li>
        </ul>
    </>
}

export default Menus;