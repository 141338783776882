
import { useState, useEffect } from "react";
import { useFormik } from 'formik';


import { Profile, SaveBasic, SaveAddress, SaveOthers, SaveSocial } from '../../../apis/User';
import Subscribe from "../../../components/Subscribe";
import Menus from "../hospitals/menus";

const ProfilePage = () => {

    const [profile, setProfile] = useState(
        { name: "", email: "", phone: "", type: 0, job_title: "", address: "", city: "", state: "", pincode: "", country: "", facebook: "", twitter: "", linkedin: "" }
    );

    const [loadingBasic, setLoadingBasic] = useState(false);
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [loadingOther, setLoadingOther] = useState(false);
    const [loadingSocial, setLoadingSocial] = useState(false);

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        Profile().then(async function (response) {
            setProfile(response.data)
        });
    }

    const [error, setError] = useState("");

    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }


    const validateBasic = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Please enter name';
        } else if (values.name.length < 2) {
            errors.name = 'Minimum name length is 2 charector';
        }

        if (!values.phone) {
            errors.phone = 'Please enter phone number';
        } else if (values.phone.length > 10) {
            errors.phone = 'Must be 10 characters or less';
        }


        return errors;
    };

    const formikBasic = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: profile.name,
            phone: profile.phone,
            job_title: profile.job_title
        },
        validate: validateBasic,
        onSubmit: async function (values) {
            setLoadingBasic(true);
            await SaveBasic(values)
            setLoadingBasic(false);
        },
    });

    const validateAddress = values => {
        const errors = {};

        if (!values.address) {
            errors.address = 'Please enter address';
        } else if (values.address.length < 7) {
            errors.address = 'Minimum address length is 6 charector';
        }

        if (!values.city) {
            errors.city = 'Please enter city';
        } else if (values.city.length < 2) {
            errors.city = 'Must be 2 characters or less';
        }

        if (!values.state) {
            errors.state = 'Please enter state';
        } else if (values.state.length < 2) {
            errors.state = 'Must be 2 characters or less';
        }

        if (!values.pincode) {
            errors.pincode = 'Please enter pincode';
        } else if (values.pincode.length < 6) {
            errors.pincode = 'Must be 6 characters or less';
        }

        if (!values.country) {
            errors.country = 'Please enter country';
        } else if (values.country.length < 2) {
            errors.country = 'Must be 2 characters or less';
        }


        return errors;
    };

    const formikAddress = useFormik({
        enableReinitialize: true,
        initialValues: {
            address: profile.address,
            city: profile.city,
            state: profile.state,
            pincode: profile.pincode,
            country: profile.country,
            update_address: 1
        },
        validate: validateAddress,
        onSubmit: async function (values) {
            setLoadingAddress(true);
            await SaveAddress(values)
            setLoadingAddress(false);
        },
    });

    const validateOther = values => {
        const errors = {};

        if (!values.age) {
            errors.age = 'Please enter age';
        }
        else if (values.age == 0) {
            errors.age = 'Please enter age';
        }

        if (!values.mother_tongue) {
            errors.mother_tongue = 'Please enter mother tongue';
        }


        return errors;
    };


    const formikOther = useFormik({
        enableReinitialize: true,
        initialValues: {
            age: profile.age,
            experience: profile.experience,
            mother_tongue: profile.mother_tongue,
        },
        validate: validateOther,
        onSubmit: async function (values) {
            setLoadingOther(true);
            await SaveOthers(values)
            setLoadingOther(false);
        },
    });

    const validateSocial = values => {
        const errors = {};

        console.log(values);

        if (values.facebook != undefined)
            if (values.facebook.length > 0) {
                if (!isValidUrl(values.facebook))
                    errors.facebook = 'Please enter valid url';
            }

        if (values.twitter != undefined)
            if (values.twitter.length > 0) {
                if (!isValidUrl(values.twitter))
                    errors.twitter = 'Please enter valid url';
            }

        if (values.linkedin != undefined)
            if (values.linkedin.length > 0) {
                if (!isValidUrl(values.linkedin))
                    errors.linkedin = 'Please enter valid url';
            }


        return errors;
    };


    const formikSocial = useFormik({
        enableReinitialize: true,
        initialValues: {
            facebook: profile.facebook,
            twitter: profile.twitter,
            linkedin: profile.linkedin,
        },
        validate: validateSocial,
        onSubmit: async function (values) {
            setLoadingSocial(true);
            await SaveSocial(values)
            setLoadingSocial(false);
        },
    });


    return (
        <>
            <section class="account-section ptb-100" style={{ marginLeft: '8%', marginRight: '8%' }}>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="account-information">
                                <div class="profile-thumb">
                                    <img src="assets/img/account.jpg" alt="account holder image" />
                                    <h3 className="text-capitalize">{profile.name ?? ""}</h3>
                                    <p>{formikBasic.values.job_title ?? ""}</p>
                                </div>

                                <Menus type={profile.type} page={"profile"} />

                                {/* <ul>
                                    <li>
                                        <a href="#" class="active">
                                            <i class='bx bx-user'></i>
                                            My Profile
                                        </a>
                                    </li>
                                    {
                                        profile.type == 0 ?
                                            <>
                                                <li>
                                                    <a href="resume.html">
                                                        <i class='bx bxs-file-doc'></i>
                                                        My Resume
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i class='bx bx-briefcase'></i>
                                                        Applied Job's
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i class='bx bx-heart'></i>
                                                        Saved Jobs
                                                    </a>
                                                </li>
                                            </>

                                            :
                                            <>
                                                <li>
                                                    <a href="./profile/hospital-clinics">
                                                        <i class='bx bxs-buildings'></i>
                                                        Hospital/Clinic Details
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i class='bx bx-briefcase'></i>
                                                        Applicants
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i class='bx bx-purchase-tag-alt'></i>
                                                        Jobs
                                                    </a>
                                                </li>
                                            </>
                                    }


                                    <li>
                                        <a href="#">
                                            <i class='bx bx-envelope'></i>
                                            Messages
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#">
                                            <i class='bx bx-lock-alt' ></i>
                                            Change Password
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class='bx bx-coffee-togo'></i>
                                            Delete Account
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class='bx bx-log-out'></i>
                                            Log Out
                                        </a>
                                    </li>
                                </ul> */}
                            </div>
                        </div>

                        <div class="col-md-9">
                            <div class="account-details">
                                <h3>Basic Information</h3>
                                <form class="basic-info" onSubmit={formikBasic.handleSubmit}>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input type="text" class="form-control" placeholder="Name" name="name" value={formikBasic.values.name ?? ""} onChange={formikBasic.handleChange} />
                                                {formikBasic.errors.name ? <div className="error">{formikBasic.errors.name}</div> : null}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="email" name="email" class="form-control" placeholder="Email" value={profile.email ?? ""} readonly />
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Phone</label>
                                                <input type="number" name="phone" class="form-control" placeholder="Phone" value={formikBasic.values.phone ?? ""} onChange={formikBasic.handleChange} />
                                                {formikBasic.errors.phone ? <div className="error">{formikBasic.errors.phone}</div> : null}
                                            </div>
                                        </div>

                                        {
                                            profile.type == 0 &&
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Job Title</label>
                                                    <input type="text" class="form-control" placeholder="Job Title" name="job_title" value={formikBasic.values.job_title ?? ""} onChange={formikBasic.handleChange} />
                                                </div>
                                            </div>
                                        }


                                        <div class="col-md-12">

                                            <button type="submit" class="account-btn">
                                                {

                                                    loadingBasic == true
                                                        ?
                                                        <><i class="fa fa-spinner fa-spin"></i> Saving...</>
                                                        :
                                                        "Save"
                                                }
                                            </button>

                                        </div>
                                    </div>
                                </form>

                                <h3>Address</h3>
                                <form class="-candidate-address" onSubmit={formikAddress.handleSubmit}>
                                    <div class="row">

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Address</label>
                                                <input type="text" name="address" class="form-control" placeholder="Address" value={formikAddress.values.address ?? ""} onChange={formikAddress.handleChange} />
                                                {formikAddress.errors.address ? <div className="error">{formikAddress.errors.address}</div> : null}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>City</label>
                                                <input type="text" name="city" class="form-control" placeholder="City" value={formikAddress.values.city ?? ""} onChange={formikAddress.handleChange} />
                                                {formikAddress.errors.city ? <div className="error">{formikAddress.errors.city}</div> : null}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>State</label>
                                                <input type="text" name="state" class="form-control" placeholder="State" value={formikAddress.values.state ?? ""} onChange={formikAddress.handleChange} />
                                                {formikAddress.errors.state ? <div className="error">{formikAddress.errors.state}</div> : null}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Pincode</label>
                                                <input type="number" name="pincode" class="form-control" placeholder="Pin Code" value={formikAddress.values.pincode ?? ""} onChange={formikAddress.handleChange} />
                                                {formikAddress.errors.pincode ? <div className="error">{formikAddress.errors.pincode}</div> : null}
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Country</label>
                                                <input type="text" name="country" class="form-control" placeholder="Country" value={formikAddress.values.country ?? ""} onChange={formikAddress.handleChange} />
                                                {formikAddress.errors.country ? <div className="error">{formikAddress.errors.country}</div> : null}
                                            </div>
                                        </div>


                                        <div class="col-md-12">
                                            <button type="submit" class="account-btn">
                                                {

                                                    loadingAddress == true
                                                        ?
                                                        <><i class="fa fa-spinner fa-spin"></i> Saving...</>
                                                        :
                                                        "Save"
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                {
                                    profile.type == 0 &&
                                    <>
                                        <h3>Other Information</h3>
                                        <form class="cadidate-others" onSubmit={formikOther.handleSubmit}>
                                            <div class="row">
                                                {
                                                    profile.type == 0 &&
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Age</label>
                                                            <input type="number" name="age" class="form-control" placeholder="Age" value={formikOther.values.age ?? ""} onChange={formikOther.handleChange} />
                                                            {formikOther.errors.age ? <div className="error">{formikOther.errors.age}</div> : null}
                                                        </div>
                                                    </div>
                                                }


                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Work Experience</label>
                                                        <input type="number" name="experience" class="form-control" placeholder="Work Experience" value={formikOther.values.experience ?? ""} onChange={formikOther.handleChange} />
                                                        {formikOther.errors.experience ? <div className="error">{formikOther.errors.experience}</div> : null}
                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Mother Tongue</label>
                                                        <input type="text" name="mother_tongue" class="form-control" placeholder="Mother Tongue" value={formikOther.values.mother_tongue ?? ""} onChange={formikOther.handleChange} />
                                                        {formikOther.errors.mother_tongue ? <div className="error">{formikOther.errors.mother_tongue}</div> : null}
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <button type="submit" class="account-btn">
                                                        {

                                                            loadingOther == true
                                                                ?
                                                                <><i class="fa fa-spinner fa-spin"></i> Saving...</>
                                                                :
                                                                "Save"
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                }




                                <h3>Social links</h3>
                                <form class="candidates-sociak" onSubmit={formikSocial.handleSubmit}>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Facebook</label>
                                                <input type="text" name="facebook" class="form-control" placeholder="www.facebook.com/user" value={formikSocial.values.facebook ?? ""} onChange={formikSocial.handleChange} />
                                                {formikSocial.errors.facebook ? <div className="error">{formikSocial.errors.facebook}</div> : null}
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Twitter</label>
                                                <input type="text" name="twitter" class="form-control" placeholder="www.twitter.com/user" value={formikSocial.values.twitter ?? ""} onChange={formikSocial.handleChange} />
                                                {formikSocial.errors.twitter ? <div className="error">{formikSocial.errors.twitter}</div> : null}
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label>Linkedin</label>
                                                <input type="text" name="linkedin" class="form-control" placeholder="www.Linkedin.com/user" value={formikSocial.values.linkedin ?? ""} onChange={formikSocial.handleChange} />
                                                {formikSocial.errors.linkedin ? <div className="error">{formikSocial.errors.linkedin}</div> : null}
                                            </div>
                                        </div>


                                        <div class="col-md-12">
                                            <button type="submit" class="account-btn">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Subscribe />
        </>
    )
}

export default ProfilePage;