import React, { useState, useHook, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

const Logout = () => {
    
    useEffect(() => {
        Cookies.remove('_user_auth');

        window.location = '/signin';

    }, []);
    
    return (
        <>
        
        </>
    )
}

export default Logout;